<template>
  <div>
    <v-card title="Welcome to OMGenes!" class="pa-4">
      <v-text> 
        OMGenes is a web application for viewing VCF files and creating them through a simple Variant Call script.
      </v-text>
    </v-card>
  </div>
</template>

<script>

export default {
  name: 'HomeView'
}
</script>
